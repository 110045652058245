import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Layout from "../components/layout"

const Copy = styled.main`
  padding: 8rem 0;
  background: var(--grey);
  h1 {
    text-align: center;
    font-size: 9.6rem;
    font-weight: 400;
    padding: 4.8rem 0;
    font-family: "tahu";
    color: #e8b795;
    @media (max-width: 767px) {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }
  }
  h2 {
    font-size: 2.4rem;
    text-transform: uppercase;
    padding-bottom: 2.4rem;
  }
  p,
  li {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
  ul {
    list-style-type: square;
    list-style-position: inside;
    padding-bottom: 1.6rem;
  }
  li {
    padding-bottom: 1rem;
  }
`

const ShippingPolicyPage = () => {
  return (
    <Layout>
      <Seo
        title="Política de Envio"
        description="Nesta página podes saber tudo sobre a Política de Envio da Boomsy"
      />
      <Copy>
        <div className="container">
          <h1>Política de Envio</h1>
          <h2>Expedição</h2>
          <p>
            A Boomsy Lashes procede ao envio do(s) produto(s) encomendado(s) de
            segunda a sexta-feira, das 9h às 19h, com exceção dos feriados, e
            nos dias e horários utilizados pela empresa de logística, para a
            morada de entrega indicada pelo cliente.
          </p>
          <p>
            As encomendas são enviadas no prazo de 24 horas úteis após recepção
            do pagamento, por CTT correio registado.
          </p>
          <p>
            O prazo de entrega pode variar de acordo com o país de destino. O
            tempo estimado de entrega em Portugal Continental é de 2 dias úteis
            e de 3 dias úteis para as Ilhas.
          </p>
          <p>
            Caso não receba a sua encomenda num prazo máximo de 15 (quinze) dias
            a partir da data de pagamento da encomenda, por favor envie um
            e-mail para <a href="mailto:info@boomsy.pt">info@boomsy.pt</a>,
            informando-nos do sucedido. Findo este prazo, a Boomsy Lashes não se
            poderá responsabilizar pelo extravio da encomenda.
          </p>
        </div>
      </Copy>
    </Layout>
  )
}

export default ShippingPolicyPage
